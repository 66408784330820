// Containers

.ov-container{
    padding: 180px 0px 0;
}
.mt-70{
    margin-top: 70px;
}
.ov-blog-container {
    padding: 50px 150px !important;
}
.ov-medium-container{
    transition: margin-top .5s ease-out;
    margin-top: -242px!important;
    background-color: $white;
    border: solid 1px $sand;
    border-radius: 5px;
}
.mt-50{
    margin-top: 50px !important;
}
.mt-75{
    margin-top: 75px !important;
}

.mt-80{
    margin-top: 80px !important;
}
.mt-90{
    margin-top: 90px;
}
.mt-100{
    margin-top: 100px;
}
.mt-130{
    margin-top: 130px;
}
.mt-275{
    margin-top: 275px !important;
}
.pt-50{
    padding-top: 50px;
}
.pt-130{
    padding-top: 130px;
}
.pb-5, .py-5 {
    padding-bottom: 4rem!important;
}
.pt-5, .py-5 {
    padding-top: 4rem!important;
}
.py-10{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.pt-150{
    padding-top: 150px;
}
.p-10{
    padding: 10px !important;
}
.p-20{
    padding: 20px !important;
}
.pl-40{
    padding-left: 40px !important;
}
.pr-40{
    padding-right: 40px !important;
}
.pl-50{
    padding-left: 50px !important;
}
.pr-50{
    padding-right: 50px !important;
}
.pl-60{
    padding-left: 60px !important;
}
.pl-80{
    padding-left: 80px !important;
}

.mb-75{
    margin-bottom: 75px !important;
}

.mb-100{
    margin-bottom: 100px;
}

.w-30{
    width: 30% !important;
}
.w-70{
    width: 70% !important;
}

.ov-position{
    z-index: 99999999;
    position: absolute;
}
.ov-position-bottom{
    position: absolute;
    bottom: 0;
}

//sizes
.w-400{
    width: 400px !important;
}
.mh-40{
    min-height: 40px !important;
}
.h-40{
    height: 40px !important;
}
.w-100{
    width: 100%;
}

//padding
.ph-0{
    padding-left: 0px;
    padding-right: 0px;
}

.ph-5{
    padding-left: 5px;
    padding-right: 5px;
}