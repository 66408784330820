@import './variable';

a {
    font-family: $primary-font ;
    color: $black !important;

}

.ov-bg-white {
    background: $white !important;
}

.ov-bg-none {
    background: none !important;
}

.ov-card {
    .ov-card-body {
        padding: 1rem !important;
    }
}

.ov-mobile-card {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.ov-background {
    background: $background;
}

.ov-background-black-height {
    height: auto;
    margin-top: 70px;
}

.ov-background-black {
    // background: linear-gradient(90deg, rgb(25 19 19), transparent 50%);
    height: 65vh;
    background-color: rgb(25 19 19 / 50%);
}

.ov-double-border {

    width: 100%;
    box-shadow: 0 -1px 0 0 #939393, 0 -5px 0 0 #fff, 0 -7px 0 0 #000, 0 1px 0 0 #000, 0 5px 0 0 #fff, 0 7px 0 0 #000;
    padding: 80px;
    height: 120px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.ov-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.image-overlay {
    position: relative;
    /* To ensure the child elements are positioned relative to this div */
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: grayscale(1);
    z-index: -1;
    /* To place the background behind the content */
}


.ov-navbar {
    a {
        color: $white !important;
    }

    .column a {
        color: $black !important;
    }

    li::after {
        background: $white ;

    }

    a::after {
        background: $white ;

    }

    .navbar {
        background-color: $black !important;
    }

    .action-box {
        color: $white !important;
    }

    .primary-btn {
        background-color: $sand !important;
    }
}

.ov-mobile-header {
    background: $black;

    .ov_button {
        color: $white !important;
    }

    .responsive-chat-icon {
        background-color: $sand !important;
        border: none !important;
    }

    #overlay-button span,
    #overlay-button span:after,
    #overlay-button span:before {
        background-color: $white;
    }
}

.ov-custom-text-field {
    .MuiInputBase-input {
        padding: 35px 0 0 !important;
    }
}

.ov-footer {
    .ov-footer-primary {
        background-color: $light-blue !important;
        color: $white !important;

        a {
            color: $white !important;
        }
    }

    .ov-footer-secondry {
        background-color: $dark-blue !important;
    }
}

.ov-slider-heading-custom {
    position: absolute;
    bottom: 10%;
    z-index: 9999;
    left: 5%;
    color: $white !important;
    font-size: 18px !important;
    font-weight: bold !important;

}

.ov-boder {
    &.left {
        border-right: 1px dashed $sand;
    }
}

.ov-sidemnu {
    font-family: $secondary-font;

    .ov-heading {
        color: $white;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    .ov-sub-heading {
        color: $white;
    }

    .ov-description {
        color: $white;
        line-height: 1rem !important;

    }

    .responsive-chat-icon {
        position: inherit !important;
        margin-top: 20px;
        margin-left: auto !important;
    }
}

.ov-hero {
    height: 80vh;
    object-fit: cover;
    width: 100%;
}

.ov-hero-text-wrapper1 {
    position: absolute;
    bottom: 50%;
    right: 50%;
}

.ov-hero-text-wrapper2 {
    position: absolute;
    bottom: 50%;
    left: 25%;
}

.about-detail-slider {
    .slick-slide {
        padding: 0 10px !important;
    }
}

.ov-staff-slider {
    height: auto;
    width: auto;

}

.ov-slider-counter {
    position: absolute;
    margin-top: -42px;
    margin-left: 0.4%;
    color: $white;
    // background: #1c222f;
    padding: 3px 10px;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 3%;
}

.ov-slider-counter-resort {
    position: absolute;
    margin-top: -40px;
    margin-left: 0.4%;
    color: $white;
    // background: #1c222f;
    padding: 3px 10px;
    border-radius: 2px;
    font-size: 12px;
    left: 50%;
}

.slider-arrow {
    font-size: 30px;
    background: none;
    cursor: pointer;
    z-index: 1;
    color: black;
}

.custom-next-arrow-in-resort {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 15px;
    margin-left: 23px;
    transform: translate(-50%, -50%);
}

.custom-prev-arrow-in-resort {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100%;
    right: 50%;
    margin-top: 15px;
    margin-right: 15px;
    transform: translate(-50%, -50%);
}

.ov-slider-counter-resort-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.room-slider-inner-slider-next {
    font-size: 30px;
    background: none;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 105%;
    right: 20px;
    z-index: 1;
    transform: translateY(-50%);
    color: black;
}


.room-slider-inner-slider-prev {
    font-size: 30px;
    background: none;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 105%;
    right: 65px;
    z-index: 1;
    transform: translateY(-50%);
    color: black;
}

.slider-counter-room-slider {
    position: absolute;
    right: 36px;
    top: 101%;
    color: black;
    margin-top: 0;
    font-size: 15px;
}

.ov-slider-icon .ov-slider-icon-custom .fa-angle-left {
    left: 40%;
}

.ov-slider-icon .ov-slider-icon-custom .fa-angle-right {
    right: 80px;
}


.ov-slider-description {
    margin-right: 35%;
    padding-top: 20%;
    padding-left: 20%;
}

.ov-img-medium {
    height: 65vh;

}

.ov-gallery-img {
    height: 200px;
    width: 200px;
}

.ov-bg-label {
    background: #2d2d2d;
    color: #fff;
    padding: 10px;
    margin-top: 25px;
    text-align: center;
}

.ov-bg-ash {
    background-color: $ash-light !important;
}

.ov-about-card {
    border: solid 1px $black;
}


.ov-about-us {
    .ov-mega-menu-wrapper {

        margin-top: 2% !important;
        width: 62% !important;

    }

}

.ov-slider-wrapper {
    .slick-dots {
        bottom: 25px !important;

        li {
            margin: 0 10px;

            button:before {
                font-size: 15px !important;
                color: $white ;
            }
        }
    }

}

.leaf-vector {
    // background-image: url('../images/leaf-vector.png');
    background-repeat: no-repeat;
    background-size: auto 50%;

}

.leaf-vector-end {
    margin-bottom: 200px;
}

.leaf-vector-end-0 {
    background-image: url('../images/leaf-vector.webp');
    background-repeat: no-repeat;
    background-size: auto 90%;
    background-position: 100% 0;
    // background-color: $ash-50;

}

.leaf-vector-null {
    background-repeat: no-repeat;
    background-size: auto 90%;
    background-position: 100% 0;
    background-color: $ash-50;
}

.ov-abt-img {
    background: url('../images/About_us_header_img.jpg');
    height: 90vh;
    background-size: cover;
    /* Ensure the background image covers the entire element */
    background-position: center;
    /* Center the background image within the element */
}

.ov-tnx-img {
    background-image: url('../images/Footerbanner.jpg');
    height: 90vh;
    background-size: cover;
    /* Ensure the background image covers the entire element */
    background-position: center;
    /* Center the background image within the element */
}

.ov-partner-logo {
    height: 100px;
    width: 100px;
}

.ov-carousel {
    color: $black;
    line-height: 2;
    font-size: 20px;
    font-family: $primary-font;
}

.ov-hero-slider1 {
    background: url('../images/Nisha-image.jpg');
    height: 94vh;
    background-size: cover;
    display: block;
    align-items: center;
    justify-content: center;

}

.ov-hero-slider2 {
    background: url('../images/shaffi-image.jpg');
    height: 97vh;
    background-size: cover;
    display: block;
    align-items: center;
    justify-content: center;


}

.ov-hero-slider3 {
    background: url('../images/maira.webp');
    height: 96vh;
    background-size: cover;
    display: block;
    align-items: center;
    justify-content: center;


}

/* Common styles for both mobile and desktop */
.ov-home-slider1 .background-image-home-slider,
.ov-home-slider2 .background-image-home-slider {
    background-size: cover !important;
    align-items: center !important;
    justify-content: center !important;
    background-repeat: no-repeat !important;
    flex-direction: column;
    max-width: 100vw;
    width: 100vw;
}

/* Styles for desktop */
@media (min-width: 768px) {
    .ov-home-slider1 .background-image-home-slider {
        height: 90vh;
    }

    .ov-home-slider2 .background-image-home-slider {
        height: 90vh;
    }

    .ov-home-slider1 .background-image-home-slider {
        background: url('../images/homepagebanner.webp');
    }

    .ov-home-slider2 .background-image-home-slider {
        background: url('../images/homepagebanner2.webp');
    }
}

/* Styles for mobile */
@media (max-width: 767px) {
    .ov-home-slider1 {
        background: url('../images/Banner-1.webp');
    }

    .ov-home-slider2 {
        background: url('../images/Banner-2.webp');
    }
}

.ov-accordion-body {
    background: $white;
    padding: 25px;
}

.ov-expand-box {
    background: $white;
    height: 400px;
}

.ov-villa-detail-box {
    height: 280px;


}

.ov-villa-slider {

    .slick-prev,
    .slick-next {
        background: none !important;
        z-index: 999;

        &:before {
            color: $black !important;
        }
    }

    .slick-prev {
        left: 0 !important;
        right: 25px !important;
        top: 30%;

    }

    .slick-next {
        right: 0px !important;
        top: 30%;
    }

    .ov-villa-extra-detail-box {

        .ov-villa-icon {
            height: 20px;
            filter: invert(58%) sepia(7%) saturate(0%) hue-rotate(42deg) brightness(103%) contrast(93%);

        }
    }

}


.ov-resort-nav {
    position: sticky;
    top: 0;
    background: white;
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    // padding: 10px;
    z-index: 99999;

    button {
        margin: 10px 0;
        padding: 10px 0px 10px 0px;
        font-size: 18px;
        font-family: $primary-font;
        background: none !important;
        color: $black;
        cursor: pointer;
        text-transform: uppercase !important;
        font-weight: bold;

        &.active {
            border-bottom: 3px solid #ccc;

        }
    }

    &.sticky {
        position: fixed;
        width: 100%;
    }
}



.place-card-large {

    display: none !important;
}


.ov-resort-detail {
    .ov-detail-icon {
        height: 50px;
        filter: invert(58%) sepia(7%) saturate(0%) hue-rotate(42deg) brightness(103%) contrast(93%);
    }

    .ov-detail-icon-new {
        height: 50px !important;
    }

    .gm-style .place-card-large {
        padding: 9px 4px 9px 11px;
        display: none;
    }

}

.ov-resort-card {
    padding: 50px;
    border: solid 1px $ash;
    border-radius: 5px;
}


.ov-resort-content {

    //  border: solid 1px $ash;
    //  border-radius: 3px;
    .ov-detail-box {
        border: solid 1px $ash;
        padding: 30px 20px 30px 20px;
        border-radius: 3px;

    }

    #location #Fine #Package {
        border-bottom: solid 1PX $ash;
        padding-bottom: 50px;
    }

    #Fine {
        border-bottom: solid 1PX $ash;
        padding-bottom: 50px;
    }

    #Package {
        border-bottom: solid 1PX $ash;
        padding-bottom: 50px;
    }

    .ov-detail-circle {

        height: 50px;
        width: 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $sand;
        color: $white;
        font-size: 18px;
    }

}

.ov-review-box {
    border-bottom: solid 1px $ash;
    padding-bottom: 50px;
}

.ov-rate-box {
    width: 50px;
    height: 50px;
    background-color: $sand;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: $white;
}

.ov-slider-icon {
    .fa-angle-right {
        font-size: 30px;
        background: none;
        cursor: pointer;
        padding: 10px;
        z-index: 9;
        right: 0;
        top: 110px;
        position: absolute;
    }

    .fa-angle-right:before {
        content: "\f054";
        color: $black;
        font-size: 15px;
        border: solid 1px;
        padding: 10px;
        background: $white;
    }

    .fa-angle-left {
        font-size: 30px;
        background: none;
        cursor: pointer;
        padding: 10px;
        z-index: 9;

        top: 110px;
        position: absolute;
    }

    .fa-angle-left:before {
        content: "\f053";
        color: $black;
        font-size: 15px;
        border: solid 1px;
        padding: 10px;
        background: $white;
    }

}

.ov-blog-resort {
    .ov-slider-icon {
        .fa-angle-left {

            top: 75px !important;
            left: -25px !important;


        }

        .fa-angle-right {

            top: 75px !important;
            right: -10px !important;


        }
    }
}


.searchInputs input::placeholder {
    font-size: 16px !important;
    font-family: "Copperplate" !important;
    text-transform: none !important;
}

.ov-slider-icon {
    .ov-slider-icon-custom {
        .bi-chevron-right {
            font-size: 30px;
            background: none;
            left: 48%;
            padding: 10px;
            z-index: 9999;
            top: 77%;
            position: absolute;
            cursor: pointer;
        }

        .bi-chevron-right:before {

            color: $white;
            font-size: 15px;
            padding: 10px;
            background: none !important;
            border: none !important;
        }

        .bi-chevron-left {
            font-size: 30px;
            padding: 10px;
            z-index: 9999;
            right: 50%;
            top: 74.5%;
            position: absolute;
            cursor: pointer;
        }

        .bi-chevron-left:before {

            color: $white;
            font-size: 15px;
            padding: 10px;
            background: none !important;
            border: none !important;


        }
    }
}

.ov-slider-icon-resort {
    .ov-slider-icon {
        .bi-chevron-right {
            font-size: 30px;
            background: none;
            cursor: pointer;
            padding: 10px;
            z-index: 9;
            right: 0;
            top: 45%;
            z-index: 999;
            position: absolute;
        }

        .bi-chevron-right:before {

            color: $black;
            font-size: 15px;
            border: solid 1px;
            padding: 10px;
            background: $white;
        }

        .bi-chevron-left {
            font-size: 30px;
            background: none;
            cursor: pointer;
            padding: 10px;
            z-index: 9;
            left: 0;
            top: 45%;
            z-index: 999;
            position: absolute;
        }

        .bi-chevron-left:before {

            color: $black;
            font-size: 15px;
            border: solid 1px;
            padding: 10px;
            background: $white;
        }
    }
}

.ov-product-img {
    height: 250px !important;
    background-image: url('../images/side-img-1.jpg');
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    margin: auto;
}

.ov-villa-detail-slider {
    padding-left: 20px !important;
    padding-right: 20px !important;

    .cat {
        height: 250px !important;
    }

    .slick-prev,
    .slick-next {
        background: none !important;
        z-index: 999;

        &:before {
            color: $white !important;
        }
    }


    .slick-prev {

        left: 75% !important;
        top: 85%;
    }

    .slick-next {
        right: 55px !important;
        top: 85%;
    }
}

.ov-award-small {
    margin-bottom: 30px;
}

.ov-floating-label {

    color: $white;
    font-size: 12px;
    font-weight: 700;
    height: auto;
    left: auto;
    line-height: 18px;
    position: absolute;
    text-align: center;
    top: 30px;
    width: auto;
    z-index: 5;
    padding: 5px 15px;
    background-color: $black;
    font-family: $primary-font;
}


// Custom Style from style.css file

.benefits-resort div ul {

    color: $black !important;

}

.custom-swiper-1 {
    padding: 0 0 0 0 !important;
}

.offer-bullet-list li:before {
    content: " ";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: none;
    border: 1px solid $black;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: -7px;
}

.offer-bullet-list-main li:before {
    content: " ";
    display: inline-block;
    width: 25px;
    height: 25px;
    background: none;
    border: 1px solid $black;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: -7px;
}

.offer-bullet-list-main {
    font-family: 'Bembo Regular' !important;
    font-size: 14px;
    color: $black !important;
    line-height: 2.4rem;
    margin-bottom: 1rem;
    margin-top: 18px;
    font-style: italic;
    padding: 0;
    list-style: none;
}

.hint-popup .hint-text p {
    color: $black !important;
}

.flex-f-i .icon-footer-r svg {
    filter: invert(17%) sepia(35%) saturate(1442%) hue-rotate(157deg) brightness(30%) contrast(101%)
}

.flex-f-i .sertified-text-footer {
    color: $black !important;
}

.MuiFormLabel-root,
.MuiIconButton-root {
    color: $black !important;
}

.MuiInput-underline:before {
    border-bottom: solid 1px $teritory-color !important;
}

// .Mui-focused {
//     border-bottom: solid 1px $teritory-color !important;
// }

.mobilenumberInput {
    border-bottom: solid 1px $teritory-color !important;
}

.rs-input {
    color: $black !important;
}

.prepend-button {
    background-color: $sand !important;
    color: $black !important;
}

.date-travel .rs-picker-toggle-placeholder {
    color: $black !important;
}

.form-check-input {
    accent-color: $black !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: $black !important;
}

.MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: unset !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: unset !important;
}

.MuiIconButton-root:hover {
    background-color: unset !important;
}

.MuiSvgIcon-root {
    height: 16px !important;
    width: 16px !important;
}

.MuiTypography-body1 {
    color: $black !important;
}

.MuiInputBase-input {
    font-family: $primary-font;
    color: $black !important;
}

.search-option-resort .dataResult a p {
    color: $black !important;
    font-size: 12px;
    text-transform: uppercase;
}


.searchIcon {
    .MuiSvgIcon-root {
        height: 20px !important;
        width: 20px !important;
    }
}


// .ov-mega-menu{
//     background-color: #fff;
//     border: 1px solid #d0c0b0;
//     border-radius: 5px;
//     margin-top: -12px!important;
//     transition: margin-top .5s ease-out;
//     position: relative;
//     margin: auto;
// }



.ov-hint-popup {
    .floating-button {
        position: fixed;
        bottom: 20px;
        right: 140px;
        width: 60px;
        height: 60px;
        background-color: $sand;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 18px;
        text-decoration: none;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 9999999;
        cursor: pointer;
    }

    i {
        font-size: 30px !important;
    }

    .floating-button:hover {
        background-color: $sand;
        color: $white !important;

        i {
            font-size: 35px !important;
            transition: 0.3s;
            transform: scale(1);

        }
    }

    .message {
        display: none;
        position: absolute;
        background-color: $white;
        width: 400px;
        padding: 10px;
        border-radius: 5px;
        right: 70px;
        bottom: 80px;
        z-index: 1;
        border: solid 1px $border;
    }

    .floating-button:hover .message {
        display: block;
        transition: 0.3s;
    }

}

.ov-whatssap-link {
    display: none;
    position: absolute;
    background-color: $white;
    width: auto;
    padding: 10px;
    border-radius: 5px;
    right: 7px;
    bottom: -275%;
    z-index: 1;
    border: solid 1px $ash;

}

.ov-whatsapp-box {
    .ov-qr {
        width: 150px;
        height: 150px;
    }
}

.ov-whatsapp-box:hover .ov-whatssap-link {
    display: block;
    transition: 0.3s;
}

.ov-footer {
    .ov-whatssap-link {
        display: none;
        position: absolute;
        background-color: $white;
        width: auto;
        padding: 10px;
        border-radius: 5px;
        right: 65%;
        bottom: -70%;
        z-index: 1;
        border: solid 1px $ash;

    }

    .ov-whatsapp-box {
        .ov-qr {
            width: 150px;
            height: 150px;
        }
    }

    .ov-whatsapp-box:hover .ov-whatssap-link {
        display: block;
        transition: 0.3s;
    }
}


.ov-mega-menu-wrapper {
    position: relative;
    margin-top: -50px !important;
    transition: margin-top .5s ease-out;
    color: $black !important;

    .ov-heding.secondry {
        color: $black !important;
    }

    .ov-mega-menu {
        border: 1px solid #ccc;
        background-color: #fff;
        position: relative;
        z-index: 1;
        width: auto;
        /* Adjust the width as needed */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin: auto;
        transition: 0.3s;

        &:hover {
            background: $black !important;
            color: $white !important;

            .ov-heding.secondry {
                color: #ffffff !important;
            }
        }

        .ov-mega-menu-content {
            display: none;
            // position: absolute;
            top: 100%;
            left: 0;
            background-color: #fff;
            border-top: 1px solid #ccc;
            width: 100%;
            z-index: 2;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            padding: 20px;

            .ov-right-column {
                border-left: solid 1px $black-50;
            }

            .ov-left-column {
                width: 45%;
            }

            .ov-right-column {
                width: 55%;
            }

            .ov-left-column,
            .ov-right-column {

                display: inline-block;
                vertical-align: top;
                padding: 0 10px;

                ul {
                    list-style: none;
                    padding: 0;


                    li {
                        margin-bottom: 30px;
                        font-size: 16px;
                        color: $black;
                        cursor: pointer;
                    }
                }
            }

            &:hover .ov-mega-menu-content {
                display: block;
            }

        }

        &.open {
            .ov-mega-menu-content {
                display: block;

            }
        }
    }


    .ov-content {
        margin-top: 20px;
        /* Adjust as needed */
        // Your styles for the main content
    }
}

.ov-list-items {
    margin-left: 20px;
}

.ov-menu-items .ov-paragraph {
    transition: 0.3s;
    font-size: 15px !important;
    // border-bottom: 1px solid $black-50;
    width: max-content;
}

.ov-menu-items .ov-paragraph .icon {
    display: none;
}

.ov-menu-items .ov-paragraph.active .icon {
    display: block;
}

.ov-menu-items .ov-paragraph.active {
    font-size: 16px !important;
    // border-bottom: 1px solid $black-50;
    width: max-content;
    // border-bottom: solid 1px !important;
}

.ov-menu-items ul .ov-sub-paragraph.icon {
    display: none;
}

.ov-menu-items ul .ov-sub-paragraph.active .icon {
    display: block;
}

.ov-mega-menu-button {
    cursor: pointer;
}

.ov-bullet ul {
    list-style: disc !important;
}

.ov-right-column li.ov-sub-paragraph {
    display: none;
}

.ov-right-column li.ov-sub-paragraph.active {
    display: list-item;
}

.ov-video {
    overflow: unset !important;
    position: relative !important;

    video {
        width: 100% !important;
        height: auto !important;
        position: relative !important;
    }
}

.ov-ref-body {
    height: 440px;
    min-height: 440px;
}

.ov-language-box {
    position: fixed;
    background: $black;
    top: 200px;
    width: 300px;
    right: 0px;
    z-Index: 9999;
    font-Family: $primary-font;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 11px 15px;
    border-radius: 2px;

    .ov-language-box-avatar {
        height: 100px;
        width: 80px;
    }
}

.ov-custom-textarea {
    .MuiOutlinedInput-multiline {

        height: 75px !important;
    }

    .MuiOutlinedInput-inputMultiline {

        height: 40px !important;
    }
}

.ov-custom-border {
    border-bottom: solid 1px $ash;
    border-bottom-style: dashed;
    padding-bottom: 20px;
}

.ov-custom-package {
    p {
        font-size: 18px;
        margin-top: 5px;

    }

    ul {
        li {
            margin-top: 5px !important;
        }
    }
}

///////////
li.ov-paragraph.active {
    font-weight: bold;
}

li.ov-sub-paragraph {
    font-weight: normal;
}

li.ov-sub-paragraph.active {
    font-weight: bold;
}


li.ov-sub-paragraph.text-left.ov-paragraph.small.ov-italic.ov-list.ov-bullet.active {
    font-weight: normal;
}


/// /////
/// 
/// 
/// 
.resortTag.deal-tag {
    background: $sand !important;
    border: none !important;
    color: $white;
}

.accordion i {
    font-size: 30px;
    color: $black;
}

.footer-link-wrapper .link-footer a {
    color: $black !important;
}

.custom-swiper-1 .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    color: $black !important;
}

.custom-swiper-1 .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    color: $black !important;
}

.custom-swiper-2 .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    color: $white !important;
}

.custom-swiper-2 .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    color: $white !important;
}

.plus-icon,
.minus-button {
    color: $black !important;
}

.inner-ds-text,
.inner-ds-text2 {
    color: $black !important;
}

.whatsapp-num-footer {
    color: $black !important;
}

.whatsapp-icon-footer i {
    color: $black !important;
}

.white-font-style {
    color: $black !important;
}

.experience-main-wrapper .img-title-ex {
    color: $black !important;
}

.experiance-tab-button.active {

    border-bottom-color: $black !important;
}

.cta-footer-action .cta-action-box svg {
    margin-bottom: 0px;
    fill: $sand;
}

.mobile-form-btn {
    color: $black;
}

.img-title-ex {
    color: $black;
}

p {
    font-family: $primary-font;

}

.ov-custom-schedule-form {
    height: auto !important;
    width: 460px !important;

    .close {
        top: 50px !important;
        color: $black !important;

        .enquiry-close-icon {
            font-family: $primary-font ;
            padding-right: 20px !important;
            color: $black !important;
            position: relative;
        }
    }

    .PhoneInputInput {
        font-size: 16px;
        color: $black;
    }

    .ov-custom-height {
        height: 557px !important;
        overflow: auto;
    }
}

.PhoneInputInput {
    font-size: 16px;
    color: $black;
}

////////////
// Blog Pages

.ov-blog {
    h1 {
        font-family: $primary-font ;
    }

    h2 {
        font-size: 30px !important;
        margin-bottom: 20px;

        span {
            font-size: 30px !important;

            a {
                font-size: 30px !important;
            }
        }

    }

    h3 {
        font-family: $primary-font ;
    }

    h4 {
        line-height: 30px !important;

        strong {
            span {
                font-family: $primary-font ;
                font-size: 18px !important;
            }
        }
    }

    h5 {
        font-family: $primary-font ;
    }

    h6 {
        font-family: $primary-font ;
    }

    a {
        text-decoration: underline !important;
    }

    .custom-ov-button {
        a {
            text-decoration: none !important;
        }
    }

    ul {

        padding-left: 30px;
        margin-top: 10px;

        li {
            margin-bottom: 10px !important;
            font-size: 18px !important;
            font-family: $primary-font ;
            line-height: 30px !important;
        }
    }

    ol {

        padding-left: 30px;
        margin-top: 10px;

        li {
            margin-bottom: 10px !important;
            font-size: 18px !important;
            font-family: $primary-font ;
            line-height: 30px !important;
        }
    }

    p {
        margin-bottom: 10px !important;
        font-family: $primary-font ;
        font-size: 18px !important;
    }


}

.ov-list-none {
    list-style: none !important;
}

.ds-image-content-resort-inner {
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translateY(-50%);
}

.ds-image-content-resort-inner .ds-content-text-sign.text-center {
    color: white !important;
}

.ds-image-content-resort-inner p.inner-ds-text2.text-center {
    color: white !important;
}

.ds-image-content-resort-inner h5.ov-heding.teritory.text-center.mt-4 {
    color: white;
}

ds-image-content-resort-inner .ov-description.ov-bold.mt-2 {
    color: white !important;
}

.ds-image-content-resort-inner p.ov-description.secondry.text-center.mt-4 {
    color: white !important;
    font-size: 15px;
}

.ds-image-content-resort-inner span.ov-description.ov-bold.mt-2 {
    color: white;
}

//private islands section ds section
.ds-image-content-private-island {
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translateY(-50%);
}

.ds-image-content-private-island .ds-content-text-sign.text-center {
    color: white !important;
}

.ds-image-content-private-island p.inner-ds-text2.text-center {
    color: white !important;
}

.ds-image-content-private-island h5.ov-heding.teritory.text-center.mt-4 {
    color: white;
}

.ds-image-content-private-island p.ov-description.secondry.text-center.mt-4 {
    color: white !important;
    font-size: 15px;
}

.ds-image-content-private-island span.ov-description.ov-bold.mt-2 {
    color: white;
}


.maintenance {

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    width: 50%;
    margin: auto;

    h1 {
        font-size: 50px;
        font-family: "Bembo Regular" !important;
    }

    h3 {
        font-family: "Bembo Regular" !important;
    }

    article {
        font: 20px Helvetica, sans-serif !important;
        color: #333;
        display: block;
        text-align: left;
    }

    a {
        color: #dc8100;
        text-decoration: none;
    }

    a:hover {
        color: #333;
        text-decoration: none;
    }


}

@media (max-width: 767px) {
    .maintenance {
        width: 90%;
    }
}

.product-card {

    position: relative;

    .product-room-image {
        height: 290px;
    }

    .product-card-card-main-wrapper {
        display: flex;
        flex-direction: column;
        min-height: auto;
        // border: 1px solid $black;
    }

    .product-card-main-image-wrapper {
        position: relative;

        .image-count-wrapper {
            position: absolute;
            bottom: 15px;
            right: 20px;
            background: white;
            display: flex;
            padding: 4px 7px 3px 7px;
            font-size: 12px;
            align-items: center;
            justify-content: center;
            line-height: normal;
            color: #000;
            font-family: $primary-font;
            font-weight: bold;
        }
    }

    .product-card-content-wrapper {
        padding: 10px 20px 24px 1px;

        .product-card-room-title {
            font-family: $primary-font;
            font-weight: bold !important;
            color: #1C222F;
            font-size: 18px !important;
            margin-bottom: 16px;
            line-height: normal !important;
            letter-spacing: 0.6px;
        }

        .product-card-room-details-list {
            display: flex;
            flex-direction: column;
            height: auto;

            .product-card-price-wrapper {
                margin-top: auto;
            }
        }

        .amenities-wrapper {
            margin-bottom: 52px;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .ameniti {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 7px;
                align-items: center;

                svg {
                    width: 14px;
                }

                img {
                    width: 14px;
                    height: 14px;
                }

                h6 {
                    font-family: $primary-font;
                    font-weight: 400 !important;
                    font-size: 16px !important;
                    color: #1c222f !important;
                    line-height: 1.3rem !important;
                }
            }
        }

        .product-card-price-wrapper {
            .product-card-valued-at-rate {
                font-family: $primary-font;
                font-weight: bold !important;
                font-size: 16px !important;
                color: rgba(28, 34, 47, 0.5);
                text-decoration: unset;
                margin-bottom: 4px;
            }

            .product-card-discounted-rate {
                font-family: $primary-font;
                font-weight: bold;
                font-size: 16px;
                color: #000;
                line-height: 20px !important;

                small {
                    font-size: 13px;
                    color: #000;
                }
            }

            .package-nights {
                font-size: 16px;
                color: #1c222f;
            }
        }
    }

    .product-card-button-wrapper {

        .link-button {
            font-family: $primary-font;
            font-weight: 600 !important;
            font-size: 14px !important;
            letter-spacing: 0.05em !important;
            color: #1c222f;
        }

    }

    .price-button-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: end;
        height: 85px;
        flex-wrap: wrap;
    }

    .product-card-button-wrapper {
        margin-left: auto;
    }

    .product-card-discount-wrapper {
        position: absolute;
        top: 29px;

        .product-card-discount-amount {
            background: $black;
            width: fit-content;
            padding: 4px 0px;
            min-width: 80px;
            text-align: center;
            color: white;
            font-family: $primary-font;
            font-weight: 600;
            font-size: 24px;
        }
    }

    .room-slider-inner-slider-prev,
    .room-slider-inner-slider-next {
        position: absolute;
        top: 50%;
        width: fit-content;
        padding: 5px 12px;
        background: rgba(255, 255, 255, 0.5);
        font-size: 20px;
        display: flex;
        line-height: normal;
    }

    .room-slider-inner-slider-prev {
        left: 20px;
    }

    .room-slider-inner-slider-next {
        right: 20px;
    }

    .percentage-value {
        background-color: #d0c0b0;
        color: #363a45;
        height: 1.5rem;
        padding: 0px 0.5rem;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        white-space: nowrap;
        flex-wrap: nowrap;
        border-radius: 9999px;
        font-size: 13px;
        margin-left: 5px;
    }
}

.sign-up-details-main-wrapper {
    .home-page-signup-main-title {
        font-family: $primary-font;
        line-height: 40px;
        font-style: normal;
        letter-spacing: 0px;
        text-transform: none;
    }

    .sign-up-second-content-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-left: 50px;

        .row {
            gap: 60px;
        }
    }

    .first-title {
        color: black;
        font-size: 18px;
        font-weight: 400;
        text-align: justify;
        padding-right: 50px;
    }

    .second-title {
        color: #120f0c;
        font-size: 18px;
        line-height: 40px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        text-transform: none;
        text-align: justify;

        button{
            margin-top: 20px;
        }
    }

    .third-title {
        color: #120f0c;
        font-size: 18px;
        line-height: 40px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        text-transform: none;
        margin-top: 0px;
        text-align: justify;
    }

    button {
        padding: 0px 12px !important;
        margin-top: 0px;
    }


}

.description-main-wrapper {
    .description-title {
        font-family: $primary-font;
        line-height: 40px;
        font-style: normal;
        letter-spacing: 0px;
        text-transform: none;
        text-align: center;
        color: white;
    }

    .description-image-wrapper {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: gray;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &.image-one {
            background-image: url('../images/Upgrade-Announcement.webp');
        }

        &.image-two {
            background-image: url('../images/Security-Precautions.jpg');
        }
    }

    .description-wrapper {
        ul {
            li {
                font-family: "Bembo Regular" !important;
                color: #1c222f !important;
            }
        }

        // visibility: hidden;
    }
}


.description-wrapper {
    width: 88%;
    margin: auto;
    color: #120f0c;
    font-size: 22px;
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: none;
    margin-top: 40px;

    p {

        font-size: 22px;
        line-height: 32px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0px;
        text-transform: none;
        margin-bottom: 40px;
    }
}

.login-popup-body {
    .label+.MuiInput-formControl {
        margin-top: 0px !important;
    }

    .MuiFormControl-root.MuiTextField-root {
        margin: 0px !important;
    }

    .email-wrapper {
        margin-top: 20px;
    }

    .rs-picker-daterange.rs-picker-default {
        width: 100% !important;
    }
}

.security-instruction-page {

    .ov-home-slider-1 .background-image-home-slider {
        height: 95vh;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .video-background {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);
        z-index: -1;
        /* Add optional filter */
        filter: brightness(100%);
    }

    .ov-home-slider-1 .background-image-home-slider.mobile-home-slider {
        background-position: 50% -18px !important;
        background: url('../images/ov_web_home_page_banner.jpg'); // //banner-image-home-page.jpg
    }
    .ov-home-slider-1 .background-image-home-slider.desktop-home-slider{
        background: url('../images/ov_web_home_page_banner.jpg');
    }

    
}

.banner-button-main-wrapper {
    margin-top: auto !important;
    margin-bottom: auto;
}

.security-instruction-page{
    .ov_button.primary-button{
        border: 1px solid #fff;
    }
}

.room-name-title {
    font-family: "Bembo Regular" !important;
    font-weight: bold !important;
    color: #1C222F;
    font-size: 25px !important;
    line-height: normal !important;
}

.experience-description {
    width: 77%;
    margin: auto;
    text-align: center !important;
    margin-top: 20px;
}

.ex-img-card {
    margin: 0px 30px 0px !important;
}

/* Mobile devices */
@media only screen and (max-width: 767px) {
    .security-instruction-page {
        .ov-home-slider1 {
            height: 100% !important;
        }
    }

    .sign-up-details-main-wrapper {
        .mobile-padding-remove {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        .first-title {
            font-size: 18px;
            line-height: 30px;
            padding-right: 0px;
        }

        .second-title {
            font-size: 18px;
            line-height: 30px;
            margin-top: 42px;
        }

        .third-title {
            font-size: 18px;
            line-height: 30px;
            margin-top: 42px;
        }

    }

    .description-main-wrapper {
        margin-bottom: 20px;

        .description-image-wrapper {}
    }

    .security-instruction-page {
        .ov-home-slider1 .background-image-home-slider {
            // background: url('../images/site-screen_2.jpg');
        }
    }

}

.security-modal-wrapper {
    &.modal.show {
        .modal-dialog {
            margin-top: 150px;
            max-width: 30% !important;
        }
    }

    p+p {
        margin-top: 0px !important;
    }

    .modal-body {
        padding: 1rem 1rem !important;
    }

    .security-modal-title {
        font-family: "Bembo Regular" !important;
        font-weight: bold !important;
        color: #1C222F;
        font-size: 25px !important;
        line-height: normal !important;
    }

    .security-modal-contact-info-title {
        font-family: "Bembo Regular" !important;
        font-weight: bold !important;
        color: #1C222F;
        font-size: 16px !important;
        line-height: normal !important;
        margin-top: 20px;
        margin-bottom: 5px;
    }
}

.description-main-wrapper {
    background: rgb(248 245 240);
    border: 1px solid #dfdfdf;
}

.product-page-heading {
    text-align: left;
    font-size: 30px !important;
}

.product-page-sub {
    text-align: left;
}

.product-card-outer-wrapper {
    width: calc(100vw - 16%);
    margin: auto;

    .ov-slider-icon {
        .fa-angle-right {
            right: -40px;
            top: 125px;
        }

        .fa-angle-left {
            top: 125px;
            left: -55px;
        }
    }
}

.product-page-inner-experience-title {
    font-size: 30px !important;
}



@media only screen and (max-width: 600px) {
    .product-card {
        .product-card-card-main-wrapper {
            min-height: 450px;
        }

        .product-room-image {
            height: 140px;
        }

        .product-card-content-wrapper {
            padding-top: 12px;

            .product-card-room-title {
                font-size: 16px !important;
            }

            .product-card-room-details-list {
                height: 160px;
            }
        }
    }
}


.highend-villa-wrapper {
    position: relative;

    .amenities-wrapper {
        margin-bottom: 52px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .ameniti {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 7px;
            align-items: center;

            svg {
                width: 14px;
            }

            img {
                width: 14px;
                height: 14px;
            }

            h6 {
                font-family: $primary-font;
                font-weight: 400 !important;
                font-size: 16px !important;
                color: #1c222f !important;
                line-height: 1.3rem !important;
            }
        }
    }
}

/* first-dropdown-start */
.fitst-login-dropdown-content {
    display: flex;
    visibility: hidden;
    opacity: 1;
    transition: visibility 0.11s, opacity 0.3s;
    position: absolute;
    background-color: #ffffff;
    min-width: 172px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2), 0px 11px 20px 0px rgba(0, 0, 0, 0.11);
    z-index: 1;
    padding: 6px 0px 6px 0px;
    margin-top: 10px;
    margin-left: -196px;
    left: 95px;

    &::after {
        width: 0px;
        content: '';
        position: absolute;
        top: -20px;
        left: 132px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #ffffff transparent;
    }

    &::before {
        content: '';
        position: absolute;
        top: -22px;
        left: 213px;
        border-width: 11px;
        border-style: solid;
        border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    }

    .luxury-dropdown-link {
        padding: 10px 30px;
        text-decoration: none;
        display: block;
        font-size: 12px;
        font-family: "Copperplate" !important;
        width: auto;
        white-space: nowrap;
        margin-bottom: 5px;
    }

    h5 {
        font-size: 14px;
        color: black;
        font-family: "Bembo Regular" !important;
        text-transform: none;
    }
}

.dropdown:hover .fitst-login-dropdown-content {
    visibility: visible;
    opacity: 1;
    justify-content: center;
}

/* luxury-dropdown-end */

.main-room-main-description {
    font-size: 18px;
    margin-bottom: 100px;
}

.contetn-wrapper-high-room {
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    top: -120px;
    background: #00000078;
    padding: 10px;
    min-width: 20%;

    .room-name-title {
        color: white !important;
    }

    .amenities-wrapper {
        margin-bottom: 0px !important;

        .ameniti {
            svg {
                fill: white !important;
            }

            h6 {
                color: white !important;
            }
        }
    }
}

.highend-villa-slider-prev {
    font-size: 30px;
    background: none;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 97.3%;
    z-index: 1;
    left: 49%;
    right: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
}

.highend-villa-slider-next {
    font-size: 30px;
    background: none;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 97.3%;
    left: 50.4%;
    right: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #ffffff;
}

.highend-slider-counter {
    right: 50%;
    top: 101%;
    color: #ffffff;
    margin-top: 0px;
    font-size: 15px;
    left: 48.2%;
    transform: translate(-50px, -50px);
}


.high-room-button-wrapper {
    text-align: center;
    margin-top: 15px;
}

.experience-description {
    margin-left: 0;
    text-align: left !important;
}