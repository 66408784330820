@import './style.scss';
@import './variable';
@import './button';
@import './spacing';
@import './grid';
@import './media-query';

.display-flex{
    display:flex;
    
    &.justify-content-space-evenly{
        justify-content: space-evenly;
    }
    &.justify-content-space-between{
        justify-content: space-between;
    }
}

.margin-auto{
    margin: auto;
}

.justify-content-center{
    justify-content: center !important;
}
